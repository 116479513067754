import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { translate, MenuItemLink, Responsive, useDataProvider } from 'react-admin';

import ChevronRight from '@material-ui/icons/ChevronRight';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SendIcon from '@material-ui/icons/Send';
import LaptopIcon from '@material-ui/icons/Laptop';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import SubMenu from './SubMenu';

import { Menu as MenuItems } from '../resources';
import { isMenuGroup, LocalCompany } from '../@types/common';
import MenuItemWithIcon from './MenuItemWithIcon';
import { createEvent } from '../utils/analytics';
import { isInternalEmail } from '../utils';

interface MenuProps {
  logout: Record<string, any>;
  open: boolean;
  translate: any;
}

const Menu = ({ logout, open, translate }: MenuProps) => {
  const dataProvider = useDataProvider();
  const [reportingLink, setReportingLink] = useState('');
  const currentCompany: LocalCompany = JSON.parse(
    localStorage.getItem('currentCompany') as string
  );

  let groupIndex = 0;
  const properMenuItems = MenuItems.map(menu => {
    if (isMenuGroup(menu)) {
      const index = groupIndex++;
      return { ...menu, index };
    }
    return menu;
  });

  const [openedTabs, setOpenedTabs] = useState(
    properMenuItems.filter(isMenuGroup).map(item => isMenuGroup(item) && !item.closed)
  );

  useEffect(() => {
    if (currentCompany) {
      dataProvider
        .GET_ONE_LINKS('CorporateCompany', {
          id: currentCompany.id,
        })
        .then(
          (res: {
            data: {
              id: string;
              reportingLink: string;
              plans: { id: string; type: string }[];
            };
            reportingLink: string;
          }) => {
            if (res && res.data && res.data.reportingLink) {
              setReportingLink(res.reportingLink);
            }
          }
        );
    }
  }, [currentCompany, dataProvider]);

  const handleToggle = (index: number) => {
    openedTabs[index] = !openedTabs[index];
    setOpenedTabs(openedTabs.slice());
  };

  return (
    <div>
      <SubMenu
        key={'whitelist'}
        handleToggle={() => handleToggle(0)}
        isOpen={openedTabs[0]}
        sidebarIsOpen={open}
        name={`resources.Whitelist.name`}
        icon={<ChevronRight />}
      >
        <MenuItemLink
          key={'Whitelist'}
          to={`/Whitelist`}
          primaryText={translate(`resources.Whitelist.menu`, {
            smart_count: 2,
          })}
          leftIcon={<AccountCircle />}
          id={`menu-${'Whitelist'.toLowerCase()}`}
          onClick={() => createEvent('whitelist_section_pressed')}
        />
      </SubMenu>

      {!!reportingLink && (
        <SubMenu
          key={'reporting'}
          handleToggle={() => handleToggle(1)}
          isOpen={openedTabs[1]}
          sidebarIsOpen={open}
          name={`pos.Reporting.menu`}
          icon={<ChevronRight />}
        >
          <MenuItemWithIcon
            index={0}
            link={reportingLink}
            text={translate(`resources.Reporting.name`)}
            icon={<EqualizerIcon />}
            onClick={() => createEvent('monthly_reporting_link_pressed')}
          />
        </SubMenu>
      )}

      <SubMenu
        key={'links'}
        handleToggle={() => handleToggle(2)}
        isOpen={openedTabs[2]}
        sidebarIsOpen={open}
        name={`pos.Links.menu`}
        icon={<ChevronRight />}
      >
        <MenuItemWithIcon
          index={0}
          link={'https://brandfolder.com/s/5fn8q9w5tth77sxrx79bg3k'}
          text={translate(`pos.Links.submenu.1`)}
          icon={<ArtTrackIcon />}
          onClick={() => createEvent('communication_cse_kit_link_pressed')}
        />
        <MenuItemWithIcon
          index={1}
          link={'https://brandfolder.com/s/4r98j7877hnqm848fxp9v2z'}
          text={translate(`pos.Links.submenu.2`)}
          icon={<ArtTrackIcon />}
          onClick={() => createEvent('communication_hr_kit_link_pressed')}
        />
        <MenuItemWithIcon
          index={2}
          link={
            'https://jagged-tugboat-7ff.notion.site/Webinars-l-offre-Gymlib-174d894dd22a81628801f135f64ccbfb'
          }
          text={translate(`pos.Links.submenu.3`)}
          icon={<LaptopIcon />}
          onClick={() => createEvent('webinars_link_pressed')}
        />
        <MenuItemWithIcon
          index={3}
          link={`${process.env.REACT_APP_API_URL!.replace(
            /\/api$/i,
            '/rest/upvoty/login'
          )}`}
          text={translate(`pos.Links.submenu.4`)}
          icon={<AddLocationIcon />}
          onClick={() => createEvent('upvoty_link_pressed')}
        />
        <MenuItemWithIcon
          index={4}
          link={'https://gymlib.com/fr/enseignes'}
          text={translate(`pos.Links.submenu.5`)}
          icon={<LocationOnIcon />}
          onClick={() => createEvent('partner_gyms_link_pressed')}
        />
        <MenuItemWithIcon
          index={5}
          link={'https://brandfolder.com/s/b67qx4x5tkr5qksfsvqk6pw'}
          text={translate(`pos.Links.submenu.6`)}
          icon={<YouTubeIcon />}
          onClick={() => createEvent('dashboard_tutorial_link_pressed')}
        />
      </SubMenu>
      {isInternalEmail() && (
        <>
          <SubMenu
            key={'challenge'}
            handleToggle={() => handleToggle(3)}
            isOpen={openedTabs[3]}
            sidebarIsOpen={open}
            name={`resources.Challenge.name`}
            icon={<ChevronRight />}
          >
            <MenuItemLink
              key={'Challenge'}
              to={`/Challenge`}
              primaryText={translate(`resources.Challenge.menu`, {
                smart_count: 2,
              })}
              leftIcon={<DirectionsWalkIcon />}
              id={`menu-${'Challenge'.toLowerCase()}`}
              onClick={() => createEvent('challenges_section_pressed')}
            />
          </SubMenu>
          <SubMenu
            key={'events'}
            handleToggle={() => handleToggle(4)}
            isOpen={openedTabs[4]}
            sidebarIsOpen={open}
            name={`pos.Events.menu`}
            icon={<ChevronRight />}
          >
            <MenuItemWithIcon
              index={0}
              link={
                'https://gymlib.notion.site/Votre-programme-pour-faire-de-Gymlib-un-succ-s-en-2024-b73eb2f134ce446ba61a880e98559aed'
              }
              text={translate(`pos.Events.submenu`)}
              icon={<CalendarTodayIcon />}
              onClick={() => createEvent('2024_events_calendar_link_pressed')}
            />
          </SubMenu>
        </>
      )}

      <SubMenu
        key={'contacts'}
        handleToggle={() => handleToggle(5)}
        isOpen={openedTabs[5]}
        sidebarIsOpen={open}
        name={`resources.Contacts.name`}
        icon={<ChevronRight />}
      >
        <MenuItemWithIcon
          index={0}
          link={'https://support-entreprise.gymlib.com/fr/'}
          text={translate(`resources.Contacts.menu`)}
          icon={<SendIcon />}
          onClick={() => createEvent('contacts_section_pressed')}
        />
      </SubMenu>

      <Responsive
        small={logout}
        medium={null} // Pass null to render nothing on larger devices
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose<MenuProps, MenuProps>(
  withRouter,
  connect(mapStateToProps, {}),
  translate
);

export default enhance(Menu);
